// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-header {
  margin-bottom: 0;
  display: flex;
  flex-direction: start;
  padding: 0 10px 0 10px;
  position: relative;
  height: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.tab-headers p {
  width: 60px;
  height: 3px;
  background-color: #50e6ff;
  position: absolute;
  bottom: 0;
  /* // transform: "translateY(10%)"; */
  margin-bottom: -12px;
}
.tab-headers p::after {
}
.css-1u9des2-indicatorSeparator {
  display: none;
}

.enlarge-circle {
  width: 53px;
  height: 53px;
  background-color: #3d3d3d;
  border-radius: 200px;
  position: absolute;
  border-radius: 200px;
  bottom: 13px;
  right: 15px;
  cursor: pointer;
}

.enlarge-circle > img {
  position: relative;
  transform: translate(60%, 63%);
  bottom: 0;
  right: 0;
}

.drop-body a {
  font-weight: 500 !important;
}

.drop-body a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/drops/jumpStartDrops.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,qCAAqC;EACrC,oBAAoB;AACtB;AACA;AACA;AACA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".tab-header {\n  margin-bottom: 0;\n  display: flex;\n  flex-direction: start;\n  padding: 0 10px 0 10px;\n  position: relative;\n  height: auto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.tab-headers p {\n  width: 60px;\n  height: 3px;\n  background-color: #50e6ff;\n  position: absolute;\n  bottom: 0;\n  /* // transform: \"translateY(10%)\"; */\n  margin-bottom: -12px;\n}\n.tab-headers p::after {\n}\n.css-1u9des2-indicatorSeparator {\n  display: none;\n}\n\n.enlarge-circle {\n  width: 53px;\n  height: 53px;\n  background-color: #3d3d3d;\n  border-radius: 200px;\n  position: absolute;\n  border-radius: 200px;\n  bottom: 13px;\n  right: 15px;\n  cursor: pointer;\n}\n\n.enlarge-circle > img {\n  position: relative;\n  transform: translate(60%, 63%);\n  bottom: 0;\n  right: 0;\n}\n\n.drop-body a {\n  font-weight: 500 !important;\n}\n\n.drop-body a:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
