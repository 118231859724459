import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import CodeCopyBtn from "../navigation/buttons/CodeCopyButton";
import "./index.css";

const MdFilePreviewer = ({ previewText, githubLink }) => {
  const [markdown, setMarkdown] = useState("");
  const [imgIndex, setImgIndex] = useState(0);
  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );

  const Note = ({ children }) => {
    return (
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          margin: "10px 0",
          borderLeft: "5px solid #ccc",
        }}
      >
        {children}
      </div>
    );
  };

  const renderers = {
    image: ({ src, alt }) => {
      // Construct the URL to the raw content of the image
      const imageUrl = `
      ${githubLink}${src}`;
      return <img src={imageUrl} alt={alt} />;
    },
    // Define other custom renderers as needed
    // For example, you can define renderers for text and tables
  };

  // const BlockQuote = ({ children }) => {
  //   // let children1 = [...children];
  //   children[1] = (
  //     <strong style={{ color: "orange" }}>
  //       {children[1].props.children[0].replace("[!NOTE]", "")}
  //     </strong>
  //   );
  //   return (
  //     <blockquote
  //       style={{
  //         backgroundColor: "#2F3646",
  //         color: "#B2CDFF",
  //         // color: "green",
  //         paddingLeft: "10px",
  //         fontSize: "14px",
  //         fontStyle: "normal",
  //         fontWeight: "400",
  //         lineHeight: "20px",
  //         padding: "14px 10px",
  //         margin: "0 0 10px 0",
  //       }}
  //     >
  //       <span style={{ display: "flex", alignItems: "start" }}>
  //         <img
  //           src="./icon/note-info.svg"
  //           style={{ marginTop: "13px", marginRight: "5px" }}
  //         />
  //         <span style={{ marginLeft: "" }}>{children}</span>
  //       </span>
  //     </blockquote>
  //   );
  // };

  const BlockQuote = ({ children, props }) => {
    // Create a new array with modified children
    console.log(children);
    let content = "";
    try {
      if (
        children[1]?.props?.children[0].props.children
          .toUpperCase()
          .includes("IMPORTANT")
      )
        content = "Important";
      var modifiedChildren = children;
    } catch (e) {
      try {
        if (
          children[1]?.props?.children[0]?.includes("[!NOTE]") ||
          children[1]?.props?.children?.includes("[!NOTE]") ||
          children[1]?.props?.children[0]?.includes("[!IMPORTANT]") ||
          children[1]?.props?.children?.includes("[!IMPORTANT]") ||
          children[1]?.props?.children[0]?.includes("[!TIP]") ||
          children[1]?.props?.children?.includes("[!TIP]") ||
          children[1]?.props?.children[0]?.includes("[!CAUTION]") ||
          children[1]?.props?.children?.includes("[!CAUTION]") ||
          children[1]?.props?.children[0]?.includes("[!WARNING]") ||
          children[1]?.props?.children?.includes("[!WARNING]") ||
          children[1]?.props?.children[0]?.includes("[!DISCLAIMER]") ||
          children[1]?.props?.children?.includes("[!DISCLAIMER]")
        ) {
          var modifiedChildren = React.Children.map(
            children,
            (child, index) => {
              if (index === 1 && React.isValidElement(child)) {
                // Extract text content from child element if it's not a string
                console.log(child.props.children);
                const childContent = child.props.children;
                // typeof child.props.children === "string"
                //   ? child.props.children
                //   : React.Children.map(child.props.children, (innerChild) => {
                //       console.log(typeof innerChild === "object");
                //       return typeof innerChild === "string" ||
                //         typeof innerChild === "object"
                //         ? typeof innerChild === "object"
                //           ? JSON.stringify(innerChild)
                //           : innerChild
                //         : "";
                //     }).join("");

                // Modify the second child if it's a valid React element
                let modifiedContent = "";
                console.log(childContent[0].includes("[!IMPORTANT]"));
                if (
                  childContent[0].includes("[!IMPORTANT]") ||
                  childContent.includes("[!IMPORTANT]")
                ) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!IMPORTANT]",
                              "<strong>Important:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!IMPORTANT]",
                      "<strong>Important:</strong>"
                    );
                  content = "Important";
                } else if (childContent[0].includes("[!TIP]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace("[!TIP]", "<strong>Tip:</strong>")
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  modifiedContent = childContent.replace(
                    "[!TIP]",
                    "<strong>Tip:</strong>"
                  );
                  content = "Tip";
                } else if (childContent[0].includes("[!CAUTION]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!CAUTION]",
                              "<strong>Caution:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!CAUTION]",
                      "<strong>Caution:</strong>"
                    );

                  content = "Caution";
                } else if (childContent[0].includes("[!DISCLAIMER]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!DISCLAIMER]",
                              "<strong>Disclaimer:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!DISCLAIMER]",
                      "<strong>Disclaimer:</strong>"
                    );

                  content = "Disclaimer";
                } else if (childContent.includes("[!WARNING]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!WARNING]",
                              "<strong>Warning:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!WARNING]",
                      "<strong>Warning:</strong>"
                    );
                  content = "Warning";
                } else {
                  console.log(typeof childContent);
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace("[!NOTE]", "<strong>Note:</strong>")
                          : typeof child === "object"
                          ? child.props?.children
                            ? `<strong>${child.props.children}</strong>`
                            : null
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!NOTE]",
                      "<strong>Note:</strong>"
                    );
                  content = "Note";
                }
                console.log(modifiedContent);
                return (
                  <span dangerouslySetInnerHTML={{ __html: modifiedContent }} />
                );
              }
              // Return the child unchanged
              return child;
            }
          );
        } else var modifiedChildren = children;
      } catch (err) {
        var modifiedChildren = children;
      }
    }
    console.log(modifiedChildren);

    return (
      <blockquote
        style={{
          backgroundColor:
            content === "Important"
              ? "#5E5176"
              : content === "Tip"
              ? "#3A5937"
              : content === "Caution"
              ? "#75383C"
              : content === "Disclaimer"
              ? "#625D31"
              : content === "Warning"
              ? "#625D31"
              : "#2F3646",
          color:
            content === "Important"
              ? "#F3ECFF"
              : content === "Tip"
              ? "#D8FFD5"
              : content === "Caution"
              ? "#FFE2E3"
              : content === "Disclaimer"
              ? "#FFF492"
              : content === "Warning"
              ? "#FFF492"
              : "#B2CDFF",
          paddingLeft: "10px",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
          padding: "14px 10px",
          margin: "0 0 10px 0",
          borderRadius: "10px",
        }}
      >
        <span style={{ display: "flex", alignItems: "start" }}>
          <img
            src={
              content === "Important"
                ? "./icon/important-info.svg"
                : content === "Tip"
                ? "./icon/tip.svg"
                : content === "Caution"
                ? "./icon/caution-info.svg"
                : content === "Disclaimer"
                ? "./icon/disclaimer-info.svg"
                : content === "Warning"
                ? "./icon/disclaimer-info.svg"
                : "./icon/note-info.svg"
            }
            style={{
              marginTop: content === "" ? "13px" : "2px",
              marginRight: "5px",
            }}
          />
          {/* Render the modified children */}
          <span style={{ marginLeft: "" }}>{modifiedChildren}</span>
        </span>
      </blockquote>
    );
  };

  const EmphasizedNote = ({ children }) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "20px",
          background: children === "IMPORTANT" && "red",
          // color: "white",
        }}
      >
        {children}
      </span>
    );
  };

  return (
    <div>
      <ReactMarkdown
        className="post-markdown"
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className = "blog-code", children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            const codeText = children; // Assuming there is only one child
            // console.log(
            //   children,
            //   inline,
            //   node?.properties?.className[0]?.split("-")[1]
            // );

            const copyToClipboard = () => {
              navigator.clipboard
                .writeText(codeText)
                .then(() => console.log("done"))
                .catch((error) => console.error("Failed to copy:", error));
            };
            return !inline && match ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="cli-code"
                  style={{
                    backgroundColor: "#666666",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      padding: "5px",
                    }}
                    onClick={copyToClipboard}
                  >
                    <div>{node?.properties?.className[0]?.split("-")[1]}</div>
                    <img
                      width={"23px"}
                      src="./icon/copy-square.svg"
                      alt="Copy"
                    />{" "}
                    {/* Add alt text */}
                  </div>
                </div>
                <div
                  className="cli-code-text"
                  style={{
                    backgroundColor: "#282C34",
                    minWidth: "20px",
                    minHeight: "40px",
                    width: "100%",
                    color: "#fff",
                    // whiteSpace: "nowrap",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    borderRadius: "3px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  <div
                    style={{
                      padding: "15px 10px 10px 10px",
                      whiteSpace: "pre-line",
                      wordBreak: "break-word",
                    }}
                  >
                    <code className="language-shell" {...props}>
                      {children}
                    </code>
                  </div>
                </div>
              </div>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          p: ({ node, children, ...props }) => {
            try {
              const isBlockQuote =
                children && children[0]?.props?.children?.startsWith("Note:");
              console.log(children);
              return (
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",

                    fontStyle: "normal",
                    fontWeight: "400",

                    color: isBlockQuote ? "inherit" : "#fff",
                  }}
                  {...props}
                >
                  {children}
                </p>
              );
            } catch (e) {
              console.log(e);
            }
          },

          h3: ({ node, children, ...props }) => {
            return (
              <h3
                style={{
                  fontSize: "16px",
                  lineHeight: "16px",

                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#FFFFFF",
                  // line-height: 20px
                }}
                {...props}
              >
                {children}
              </h3>
            );
          },
          img: ({ src, alt }) => {
            let imageUrl = "";
            let mainImageUrl = "";
            let masterImageUrl = "";
            console.log(src.includes("badge"));
            if (src.includes("badge")) return;

            if (!src.includes("http")) {
              if (src.startsWith(".")) {
                imageUrl = `${githubLink}${src.substring(1)}`;
                mainImageUrl = `${githubLink}/main${src.substring(1)}`;
                masterImageUrl = `${githubLink}/master${src.substring(1)}`;
              } else if (src.startsWith("/")) {
                imageUrl = `${githubLink}${src}`;
                mainImageUrl = `${githubLink}/main${src}`;
                masterImageUrl = `${githubLink}/master${src}`;
              } else {
                imageUrl = `${githubLink}/${src}`;
                mainImageUrl = `${githubLink}/main/${src}`;
                masterImageUrl = `${githubLink}/master/${src}`;
              }

              const urls = [imageUrl, mainImageUrl, masterImageUrl];

              const promises = urls.map((url) => fetch(url));

              Promise.all(promises)
                .then((responses) => {
                  const results = responses.map((response) => {
                    if (response.status === 200) return true;
                    else return false;
                  });
                  return Promise.all(results);
                })
                .then((data) => {
                  console.log(data);
                  setImgIndex(data.indexOf(true));
                })
                .catch((error) => console.error(error));
            }
            return (
              <img
                // width={650}
                style={{ maxWidth: "-webkit-fill-available" }}
                src={
                  src.includes("http")
                    ? src
                    : imgIndex === 0
                    ? imageUrl
                    : imgIndex === 1
                    ? mainImageUrl
                    : masterImageUrl
                }
                alt={alt}
              />
            );
          },

          li: ({ node, children, ...props }) => {
            return (
              <li
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",

                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#FFFFFF",
                  // line-height: 20px
                }}
                {...props}
              >
                {children}
              </li>
            );
          },
          ul: ({ node, children, ...props }) => {
            return (
              <div>
                <ul
                  style={
                    {
                      //   paddingLeft: 0,
                      // line-height: 20px
                    }
                  }
                  {...props}
                >
                  {children}
                </ul>
              </div>
            );
          },

          a: ({ node, children, ...props }) => {
            return (
              <a
                target="_blank"
                style={{ textDecoration: "none" }}
                onMouseOver={() => {
                  // Add underline style on hover
                  this.style.textDecoration = "underline";
                }}
                onMouseOut={() => {
                  // Remove underline style on mouseout
                  this.style.textDecoration = "none";
                }}
                {...props}
              >
                {children}
              </a>
            );
          },

          table: ({ node, children, ...props }) => {
            return (
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  tableLayout: "fixed",
                  border: "1px solid #BDBDBD",
                }}
                {...props}
              >
                {children}
              </table>
            );
          },
          th: ({ node, children, ...props }) => {
            return (
              <th
                style={{
                  // Maxwidth: "40px",

                  padding: "10px 12px",
                  textAlign: "left",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontSeight: "400",
                  lineHeight: "24.49px" /* 153.06% */,
                  letterSpacing: "0.48px",
                  border: "1px solid #BDBDBD",
                  background: "var(--Neutral-Table-Header-Background, #38434D)",
                }}
                {...props}
              >
                {children}
              </th>
            );
          },
          td: ({ node, children, ...props }) => {
            return (
              <td
                style={{
                  wordWrap: "break-word",
                  color: "",
                  background: "#242424",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  textAlign: "left",
                  lineHeight: "24.49px" /* 153.06% */,
                  letterSpacing: "0.48px",
                  border: "1px solid #BDBDBD",
                  padding: "8px",
                }}
                {...props}
              >
                {children}
              </td>
            );
          },
          a: ({ node, href, children, ...props }) => {
            const requiredUrl = githubLink?.split("/");
            let modifiedhref = href;
            if (!href?.includes("http") && githubLink)
              modifiedhref = `https://github.com/${requiredUrl[3]}/${requiredUrl[4]}/blob/${requiredUrl[5]}/${href}`;

            return (
              <a href={modifiedhref} {...props} target="_blank">
                {children}
              </a>
            );
          },

          blockquote: BlockQuote,
          strong: EmphasizedNote,
        }}
      >
        {previewText}
      </ReactMarkdown>
    </div>
  );
};

export default MdFilePreviewer;
// style={{
//   display: "flex",
//   width: "445px",
//   height: "683px",
//   padding: "60px 38.5px 30px 38.5px",
//   flexDirection: "column",
//   alignItems: "center",
// }}
